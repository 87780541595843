import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_copingTipsDialog'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SingleImage = makeShortcode("SingleImage");
const ButtonDialog = makeShortcode("ButtonDialog");
const Box = makeShortcode("Box");
const Typography = makeShortcode("Typography");
const GoTo = makeShortcode("GoTo");
const Paper = makeShortcode("Paper");
const TextCustom = makeShortcode("TextCustom");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "having-the-conversations"
    }}>{`Having the conversations`}</h2>
    <p>{`Supporting your teen through the pandemic starts by acknowledging the elephant in the room. Even though we’ve been dealing with pandemic for some time now, it’s important to keep the conversations going. Constantly changing local regulations and restrictions can make your teen more anxious. You can help your teen feel more in control by providing them with clear, up-to-date and age-appropriate information about the pandemic and restrictions when the situation changes.`}</p>
    <p>{`Talking with your teen can also help you understand what’s on their mind and how they are coping.
So where do you start?`}</p>
    <p>{`One way is to think of it like cooking. Start by having all the right ingredients, and make all the preparations before you heat up the pan.`}</p>
    <Grid container justify="center" mdxType="Grid">
  <Grid item xs={12} mdxType="Grid">
    <SingleImage gridSize={6} smallGridSize={4} src="/images/m2/ingredients.svg" mdxType="SingleImage" />
  </Grid>
  <Grid item xs={10} mdxType="Grid">
    <ol>
      <li>Reliable and up-to-date resources about the pandemic</li>
      <li>Understanding of your child’s current development needs and knowledge about the pandemic</li>
      <li>
        Coping strategies to manage your own worries. It’s important to manage your own anxiety before talking with your
        teen, so that you don’t reinforce your teen’s anxiety. Try to use helpful coping strategies that work for you.
      </li>
    </ol>
  </Grid>
  <Grid item xs={6} mdxType="Grid">
    <ButtonDialog buttonFullWidth linkText={'Click here for some strategies'} id="copingTipsDialog" mdxType="ButtonDialog">
      <Box mdxType="Box">
        <Typography paragraph mdxType="Typography">
          Here are some strategies that might be helpful in the moment when you’re feeling anxious. For more strategies,
          check out the ‘Self‑help strategies for mild anxiety’ guidelines on our{' '}
          <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page.
        </Typography>
        <Typography mdxType="Typography">
          <ul>
            <li>
              <b>Deep breathing - </b>inhale deeply through your nose into your abdomen for a count of five seconds.
              Hold for two seconds, then breathe out slowly for five seconds.
            </li>
            <li>
              <b>Exercise</b> or physical activity.
            </li>
            <li>
              <b>Progressive muscle relaxation - </b>This involves working through major groups of muscles in the body,
              tensing and releasing them as you go.
            </li>
            <li>
              <b>Mindfulness - </b>bring your attention to what you are experiencing at that moment, and
              non-judgementally observe your thoughts, feelings, and sensations.
            </li>
            <li>
              <b>Acceptance - </b>try to accept that there will be uncertainty and questions that can’t be answered
              during the pandemic.
            </li>
            <li>
              <b>Visualisation - </b>try to imagine yourself successfully coping with the situation you’re anxious
              about.
            </li>
          </ul>
        </Typography>
      </Box>
    </ButtonDialog>
  </Grid>
  <br />
  <Grid item xs={12} mdxType="Grid">
    <SingleImage gridSize={6} smallGridSize={4} src="/images/m2/ingredients-tail.svg" alt="Chef hat" mdxType="SingleImage" />
  </Grid>
    </Grid>
    <p>{`Once you’ve prepared the ingredients, it’s time to start cooking!`}</p>
    <p>{`First, plan how you want to discuss the pandemic with your teen. Adapt when and how much you talk with your teen based on their needs, for example if you notice them feeling more anxious, or when the local situation changes.`}</p>
    <p>{`Here are some tips to keep in mind when talking to your teen about COVID-related topics.`}</p>
    <Grid container spacing={3} justify='center' mdxType="Grid">
  <Grid item xs={12} mdxType="Grid">
    <SingleImage gridSize={6} smallGridSize={4} src="/images/m2/instructions.svg" mdxType="SingleImage" />
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Paper elevation={0} mdxType="Paper">
      <TextCustom paragraph customColor="green" variant="h3" align="center" mdxType="TextCustom">
        DO's
      </TextCustom>
      <ul>
        <li style={{
              marginTop: '20px'
            }}>
          Use a calm and reassuring tone. Even if you feel anxious yourself, try to model being calm. 
        </li>
        <li style={{
              marginTop: '20px'
            }}>
          Begin by asking what your teen already knows and whether they have any questions. Adapt the conversation based on what they already know and what they want to know. 
        </li>
        <li style={{
              marginTop: '20px'
            }}>
          Ask what worries them most about the pandemic  (e.g., risk of infection, impacts of local  restrictions, global effect of the pandemic) 
        </li>
        <li style={{
              marginTop: '20px'
            }}>
          Talk about the facts of the pandemic in a way that best suits your teen’s personality, e.g. if they are prone to anxiety or to disregard rules. 
        </li>
        <li style={{
              marginTop: '20px'
            }}>
          Be prepared to repeat information multiple times. It may be hard for your teen to understand or accept new information initially. 
        </li>
      </ul>
    </Paper>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Paper elevation={0} mdxType="Paper">
      <TextCustom paragraph customColor="maroon" variant="h3" align="center" mdxType="TextCustom">
        DONT's
      </TextCustom>
      <ul>
        <li style={{
              marginTop: '20px'
            }}>
          Avoid lecturing, being overly anxious, catastrophising, or focusing on worst-case scenarios to make an impact.  Instead, provide honest and accurate information.
          </li>
        <li style={{
              marginTop: '20px'
            }}>
          Refrain from discussing information that is not appropriate to their age, including things they are unlikely to understand, or that may increase their anxiety (e.g. family financial difficulties).
          </li>
        <li style={{
              marginTop: '20px'
            }}>
          Don’t end the conversation when your child is upset - keep talking through what’s upsetting them and help them to calm down.
        </li>
      </ul>
    </Paper>
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      